import BSN from "bootstrap.native";
import {TransportMainController} from "../transport_main_controller";

export default class extends TransportMainController {
  static targets = [
    //  service form
    "order_services_errors",
    "order_services_errors_text",
    "service_start_at",
    "service_id",
    "add_service_btn",
    "services_table",
    "services_table_tbody",
    "services_table_total_value",
    "hidden_services",
    "service_name",
    "add_new_button_row",
    //   extra_services
    "extra_services",
    //   form
    "form_main",
    "order_modal_xl",
    "order_client_modal_xl",
    "choices_ajax_search_clients"
  ]

  async addSelectedService(event) {
    event.preventDefault();
    const btn_target = event.currentTarget;
    if (!btn_target.classList.contains("disabled")) {
        await this.addServiceToForm();
    }
  }

  removeServiceFromTable(event) {
    event.preventDefault();
    const btn_target = event.currentTarget;
    const row_id = btn_target.getAttribute("data-id");

    const row = btn_target.closest(`[data-row-id="${row_id}"]`);
    row.remove();
  }

  //PRIVATE

  addServiceToForm() {
    const add_new_button_row = this.add_new_button_rowTarget;
    const service_uniq_id = new Date().getTime();

    let row_html = `
      <div class="row" data-row-id="${service_uniq_id}">
        <div class="form-group col-md-3 mb-15">
          <div class="has-feedback mb-0">
            <input type="text" name="services[${service_uniq_id}][start_at]" class="form-control" data-controller="layout--calendar" data-target="layout--calendar.datetimepicker clients--createOrder.service_start_at" "data-require-msg"="Выберите дату" required>
            <div class="form-control-feedback">
              <span class="input-group-text btn"><i class="far fa-calendar-alt"></i></span>
            </div>
          </div>
        </div>
        <div class="form-group col-md-8 mb-15">
          <input type="text" name="services[${service_uniq_id}][service_name]" class="form-control" "data-require-msg"="Введите название услуги" required>
        </div>
        <div class="form-group col-md-1 col-sm-6 mb-15">
          <a href="#" data-action="clients--createOrder#removeServiceFromTable" data-id="${service_uniq_id}" class="btn btn-outline-danger ml-2">
            <i class="fas fa-trash"></i>
          </a>
        </div>
      </div>
    `;

    // add to before add_new_button_row
    add_new_button_row.insertAdjacentHTML("beforebegin", row_html);

  }

  reindexServicesHiddenFields(row_id) {
    const hidden_services = this.hidden_servicesTarget;
    const hidden_fields = hidden_services.querySelectorAll("input");

    hidden_fields.forEach((field) => {
      if (field.name.includes(`services_attributes][${row_id}]`)) {
        field.remove();
      }
    });
  }

  async fetchServiceData(url, params) {
    return await fetch(url, params).then(function (response) {
      return response.json();
    });
  }

  reindexFormTableRows() {
    const tbody_target = this.services_table_tbodyTarget;
    const rows = tbody_target.querySelectorAll("tr:not(.hidden)");
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      tr.querySelector("span.index").innerHTML = i + 1;
    }
  }

  calculateServicesTotalValue() {
    const hidden_services = this.hidden_servicesTarget;
    const hidden_fields = hidden_services.querySelectorAll("input");
    let total_value = 0;
    hidden_fields.forEach((field) => {
      if (field.name.includes("price")) {
        total_value += parseFloat(field.value.replace(',', '.').replace(/[^\d.-]/g, ''));
      }
    });
    total_value += this.calculateExtraServicesTotalValue();
    total_value = super.setNumericFormat(total_value);
    this.services_table_total_valueTarget.innerHTML = total_value;
  }

  calculateExtraServicesTotalValue() {
    const extra_services = this.extra_servicesTargets;
    let total_value = 0;
    extra_services.forEach((service) => {
      if (service.checked) {
        const price = service.getAttribute("data-price");
        total_value += parseFloat(price.replace(',', '.').replace(/[^\d.-]/g, ''));
      }
    });
    return total_value;
  }

  validateServiceForm() {
    const service_id = this.service_idTarget.choices.getValue(true);
    const start_at = this.service_start_atTarget.value;
    const client_id = document.getElementById('transport_order_client_id').choices.getValue(true);

    let errors = [];
    if (!client_id) {
      errors.push("Выберите клиента");
    }
    if (!service_id) {
      errors.push("Выберите услугу");
    }
    if (!start_at) {
      errors.push("Введите дату начала");
    }
    if (errors.length > 0) {
      this.order_services_errors_textTarget.innerHTML = errors.join("<br>");
      this.order_services_errorsTarget.classList.remove("hidden");
    } else {
      this.order_services_errors_textTarget.innerHTML = "";
      this.order_services_errorsTarget.classList.add("hidden");
    }
    return errors.length === 0;
  }
}